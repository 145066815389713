.login-form {
  max-width: 400px;
  width: 400px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #ddd;
  padding: 30px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}
.container-login {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fbfbfb;
  height: 100%;
}
.logo-login {
  width: 220px;
  margin-bottom: 35px;
}

.logo-container {
  justify-content: center;
  display: flex;
}
